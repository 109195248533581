import { Box, Stack, Heading, useColorModeValue } from "@chakra-ui/react";

export const Feature = (props) => {
  const { title, children, icon } = props;
  const color = useColorModeValue("gray.600", "whiteAlpha.600");

  return (
    <Stack
      spacing={{
        base: "3",
        md: "6",
      }}
      direction={{
        base: "column",
        md: "row",
      }}
    >
      <Box fontSize="5xl">{icon}</Box>
      <Stack spacing="1">
        <Heading fontWeight={600} fontSize="lg">
          {title}
        </Heading>
        <Box color={color}>{children}</Box>
      </Stack>
    </Stack>
  );
};
