import { chakra } from "@chakra-ui/system";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { default as NextLink } from "next/link";

const Link = (props) => (
  <NextLink href={props.href}>
    <chakra.a
      href="#"
      color={useColorModeValue("brand.500", "brand.200")}
      _hover={{
        color: useColorModeValue("brand.600", "brand.300"),
      }}
      display={{
        base: "block",
        sm: "inline",
      }}
      {...props}
    />
  </NextLink>
);

export default Link;
