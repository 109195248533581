import { Heading, Text } from "@chakra-ui/react";

const HeadingWithSubtitle = (props) => {
  const { title, subtitle, id, headingProps, textProps } = props;
  return (
    <>
      <Heading
        id={id || ""}
        as="h1"
        fontSize={{ base: "3xl", md: "4xl", xl: "50px" }}
        {...headingProps}
      >
        {title}
      </Heading>
      <Text mt={2} {...textProps}>
        {subtitle}
      </Text>
    </>
  );
};

export default HeadingWithSubtitle;
