import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useColorModeValue,
} from "@chakra-ui/react";

const FAQ = (props) => {
  const { items } = props;
  return (
    <>
      <Accordion allowMultiple>
        {items?.map((item, i) => (
          <AccordionItem key={i}>
            <h2>
              <AccordionButton
                _expanded={{
                  color: useColorModeValue("brand.500", "brand.300"),
                }}
              >
                <Box fontWeight={600} flex="1" textAlign="left">
                  {item.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel fontWeight={500} pb={4}>
              {item.desc}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default FAQ;
