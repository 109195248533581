import { Box, Heading, useColorModeValue } from "@chakra-ui/react";

export const ResultBox = ({ title, text }) => {
  const bgColor = useColorModeValue("white", "gray.700");

  return (
    <Box
      mb={6}
      bg={bgColor}
      shadow="base"
      w="full"
      textAlign="center"
      p={{ base: 3, md: 5 }}
      rounded="md"
    >
      <Heading fontWeight={500} fontSize="md">
        {title}
      </Heading>
      <Heading fontSize="7xl">{text}</Heading>
    </Box>
  );
};
