import { toast as chakraToast } from "@chakra-ui/react";

// Used for converting the "ID"-styled gameType to a friendly name
export const convertGameType = (gameType) => {
  switch (gameType) {
    case "planning-poker":
      return "Planning Poker";

    case "retrospective":
      return "Retrospective";

    default:
      break;
  }
};

export const getPlanningPokerVoteAverage = (votes) => {
  const filteredVotesForAveraging = votes
    // filter out "?" answers
    ?.filter((vote) => {
      return vote.value !== "?";
    })
    // replace "1/2" with 0.5
    .map((vote) => {
      return vote.value === "1/2" ? 0.5 : Number(vote.value);
    });

  const voteAverage =
    filteredVotesForAveraging?.reduce((a, b) => a + b, 0) /
    filteredVotesForAveraging?.length;

  return voteAverage % 1 === 0 ? voteAverage : voteAverage.toFixed(1);
};

export const toast = (title, options) => {
  const { status, desc } = options;

  chakraToast({
    title: title || "",
    description: desc || "",
    status: status || "",
  });
};
