import { motion } from "framer-motion";

const FadeTransition = ({ delay, children, style }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    children={children}
    style={style}
    transition={{
      type: "spring",
      ease: [0.7, 0, 0, 1],
      stiffness: 100,
      damping: 20,
      delay,
    }}
  />
);

export default FadeTransition;
