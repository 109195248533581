import {
  Sparkle,
  Infinity as InfinityIcon,
  Password,
  Broadcast,
  LockKey,
  ShieldCheck,
  Files,
  GlobeHemisphereWest,
} from "@phosphor-icons/react";
import {
  SimpleGrid,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const benefits = [
  {
    text: "Try us for Free",
    icon: Sparkle,
  },
  {
    text: "Unlimited sessions",
    icon: InfinityIcon,
  },
  {
    text: "Encrypted at rest",
    icon: Password,
  },
  {
    text: "Superfast realtime",
    icon: Broadcast,
  },
  {
    text: "GDPR Compliant",
    icon: LockKey,
    type: "secondary",
  },
  {
    text: "ISO 27001 Compliant",
    icon: ShieldCheck,
    type: "secondary",
  },
  {
    text: "SOC 1-3 Compliant",
    icon: Files,
    type: "secondary",
  },
  {
    text: "CCPA Compliant",
    icon: GlobeHemisphereWest,
    type: "secondary",
  },
];

const BenefitsList = () => {
  const primaryIconColor = useColorModeValue("brand.500", "brand.300");
  const secondaryIconColor = useColorModeValue("gray.400", "gray.400");

  return (
    <SimpleGrid
      columns={{ base: 2, lg: 4 }}
      maxW="4xl"
      width="100%"
      justifyItems="center"
      alignItems="center"
      spacing={6}
    >
      {benefits.map(({ text, icon, type }, i) => (
        <Flex align="center">
          <Icon
            as={icon}
            mr={2}
            fontSize={22}
            weight="bold"
            color={type === "secondary" ? secondaryIconColor : primaryIconColor}
          />
          <Text fontSize={{ base: "sm", md: "md" }}>{text}</Text>
        </Flex>
      ))}
    </SimpleGrid>
  );
};

export default BenefitsList;
