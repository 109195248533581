import {
  Avatar,
  Box,
  Flex,
  Heading,
  Tag,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

const BLURRED_FAKE_VOTE = 42;

export const VoterCard = ({
  index,
  isShowVotes,
  isOwnVote,
  value,
  voter: { photoURL, displayName },
}) => {
  const bgColor = useColorModeValue("white", "gray.800");

  const textColor = isShowVotes
    ? "inherit"
    : useColorModeValue("gray.400", "gray.600");

  const yourVoteIndicatorBgColor = useColorModeValue(
    "linear(to-b, green.300, green.500)",
    "linear(to-b, green.500, green.700)"
  );

  return (
    <Flex
      datatestid={`voteCard-${index}`}
      bg={bgColor}
      h="100%"
      shadow="base"
      textAlign="center"
      flexDirection="column"
      alignItems="center"
      justify="center"
      px={2}
      py={6}
      rounded="md"
      position="relative"
    >
      <Heading
        color={textColor}
        fontSize="6xl"
        mt={12}
        // blur the text
        filter={isShowVotes || isOwnVote ? "none" : "blur(15px)"}
        // disable user select if votes are hidden && isn't own vote
        userSelect={isShowVotes || isOwnVote ? "auto" : "none"}
      >
        {isShowVotes || isOwnVote ? value : BLURRED_FAKE_VOTE}
      </Heading>

      {!isShowVotes && (
        <Tag top={4} position="absolute" variant="subtle">
          Vote Hidden
        </Tag>
      )}

      <Flex mt={16} mb={6} alignItems="center">
        <Avatar src={photoURL} name={displayName} size="xs" />
        <Text fontSize="sm" ml={2}>
          {displayName}
        </Text>
      </Flex>

      {isOwnVote && (
        <Box
          // pt={2}
          bgGradient={yourVoteIndicatorBgColor}
          position="absolute"
          width="100%"
          roundedBottom="md"
          bottom={0}
          fontSize="sm"
          color="white"
          fontWeight="bold"
        >
          Your vote
        </Box>
      )}
    </Flex>
  );
};
