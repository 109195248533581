import { Box, SimpleGrid, useBreakpointValue } from "@chakra-ui/react";
import ConfettiExplosion from "react-confetti-explosion";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { getPlanningPokerVoteAverage } from "@lib/helpers";
import FadeTransition from "@components/animation/FadeTransition";
import { ResultBox } from "@components/planning-poker/ResultBox";
import { VoterCard } from "@components/planning-poker/VoterCard";

// The first mount ends too early, so we need to add some time to it
const INITIAL_MOUNT_DELAY_IN_MS = 800;

const TIME_TO_FINISH_IN_MS = 2400;
const TIME_TO_FINISH_IN_MS_MOBILE = 1700;

const BREAK_BETWEEN_CARDS_IN_MS = 2000;
const BREAK_BETWEEN_CARDS_IN_MS_MOBILE = 1800;

const CARD_VOTER = [
  {
    displayName: "Raoul Emmerich",
    photoURL: "https://randomuser.me/api/portraits/men/3.jpg",
  },
  {
    displayName: "Laurie Bins",
    photoURL: "https://randomuser.me/api/portraits/women/3.jpg",
  },
  {
    displayName: "Amelia Haag",
    photoURL: "https://randomuser.me/api/portraits/women/32.jpg",
  },
  {
    displayName: "Cathy Nolan",
    photoURL: "https://randomuser.me/api/portraits/women/33.jpg",
  },
  {
    displayName: "Keira Reichert",
    photoURL: "https://randomuser.me/api/portraits/women/34.jpg",
  },
];

// generate an array of arrays with random fibonacci numbers (max should be length of CARD_VOTER), also include "?" randomly
// the maximum amount of array items is the length of CARD_VOTER
const generateCardArray = (amountOfArrays) => {
  const fibonacci = [1, 2, 3, 5, 8, 13, 20];
  const randomFibonacci = () =>
    fibonacci[Math.floor(Math.random() * fibonacci.length)];

  return Array.from({ length: amountOfArrays }, () =>
    Array.from({ length: CARD_VOTER.length }, () =>
      Math.random() > 0.9 ? "?" : randomFibonacci()
    )
  );
};

const CARDS = [[3, 3, 3, 3, 3], [5, 8, 13, "?", 20], ...generateCardArray(5)];

export const CardsGraphic = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [isConsensus, setIsConsensus] = useState(false);
  const [isAverageVote, setIsAverageVote] = useState(false);
  const [isFinished, setFinished] = useState(false);
  const [cardsIndex, setCardsIndex] = useState(0);

  const isMobile = useBreakpointValue({ base: true, md: false });

  const timeToFinish = isMobile
    ? TIME_TO_FINISH_IN_MS_MOBILE
    : TIME_TO_FINISH_IN_MS;

  const timeBetweenCards = isMobile
    ? BREAK_BETWEEN_CARDS_IN_MS_MOBILE
    : BREAK_BETWEEN_CARDS_IN_MS;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsMounted(true);
    }, INITIAL_MOUNT_DELAY_IN_MS);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    setFinished(false);

    const areAllCardsTheSame = CARDS[cardsIndex].every(
      (card, _index, arr) => card === arr[0]
    );

    // Increase the `cardsIndex` after the first set of cards has been shown
    const timeout = setTimeout(
      () => {
        // If all cards are the same, show the consensus
        setIsConsensus(areAllCardsTheSame);
        setIsAverageVote(!areAllCardsTheSame);
      },
      isMounted ? timeToFinish : timeToFinish + INITIAL_MOUNT_DELAY_IN_MS
    );

    // Show the next set of cards after break timeout
    const finished = setTimeout(
      () => {
        setFinished(true);

        setIsConsensus(false);
        setIsAverageVote(false);

        setCardsIndex((prev) => (prev + 1) % CARDS.length);
      },
      isMounted
        ? timeToFinish + timeBetweenCards
        : timeToFinish + timeBetweenCards + INITIAL_MOUNT_DELAY_IN_MS
    );

    return () => {
      clearTimeout(timeout);
      clearTimeout(finished);
    };
  }, [cardsIndex]);

  return (
    <Box overflowX={{ base: "clip", md: "visible" }} position="relative">
      <Box width="100%" display="flex" justifyContent="center">
        {isConsensus && (
          <ConfettiExplosion
            width={1500}
            particleCount={60}
            duration={2500}
            force={0.1}
          />
        )}
      </Box>

      {(isConsensus || isAverageVote) && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 60,
            damping: 10,
          }}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: isMobile ? "15%" : "25%",
            zIndex: 1,
          }}
        >
          <Box maxW="3xl" mx="auto" padding={{ base: 4, md: 0 }}>
            {isConsensus && (
              <ResultBox title="Consensus! 🎉" text={CARDS[0][0]} />
            )}

            {isAverageVote && (
              <ResultBox
                title="Average Vote"
                text={getPlanningPokerVoteAverage(
                  CARDS[cardsIndex]
                    .slice(0, isMobile ? 3 : undefined)
                    .map((card) => ({ value: card }))
                )}
              />
            )}
          </Box>
        </motion.div>
      )}

      <motion.div
        initial={{ opacity: 1, scale: 1 }}
        animate={
          !isConsensus && !isAverageVote
            ? undefined
            : { opacity: 0.5, scale: 0.8 }
        }
        transition={{
          type: "spring",
          stiffness: 70,
          damping: 20,
        }}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <SimpleGrid
          justifyContent="center"
          alignItems="center"
          maxW="7xl"
          width="100%"
          minH="sm"
          columns={{ base: 3, md: CARDS[0].length }}
          spacing={{ base: 16, md: 3 }}
          ml={{ base: -24, md: 0 }}
        >
          {!isFinished &&
            CARDS[cardsIndex]
              .slice(0, isMobile ? 3 : undefined)
              .map((card, index) => (
                <RotatedCard key={index} index={index} card={card} />
              ))}
        </SimpleGrid>
      </motion.div>
    </Box>
  );
};

const RotatedCard = ({ index, card }) => (
  <FadeTransition
    key={index}
    style={{
      height: "100%",
    }}
    delay={index / 2}
  >
    <Box
      height={{ base: "310px", md: "100%" }}
      minWidth={{ base: "200px", md: "auto" }}
      transform={`rotate(${index % 2 === 0 ? -3 : 3}deg)`}
    >
      <VoterCard
        key={index}
        index={index}
        isShowVotes={true}
        isOwnVote={index === 1}
        value={card}
        voter={CARD_VOTER[index]}
      />
    </Box>
  </FadeTransition>
);
