import {
  Box,
  SimpleGrid,
  useColorMode,
  Text,
  Heading,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import CTA from "@components/CTA";
import Footer from "@components/common/Footer";
import { Feature } from "@components/landing/Feature";
import { PricingSection } from "@components/landing/PricingSection";
import {
  Cards,
  ClockCounterClockwise,
  Infinity,
  Kanban,
  MoonStars,
  UsersFour,
} from "@phosphor-icons/react";
import Navbar from "@components/common/Navbar";
import { functions } from "lib/firebase";
import { httpsCallable } from "firebase/functions";
import HeadingWithSubtitle from "@components/common/HeadingWithSubtitle";
import ImageColumnWithText from "@components/landing/ImageColumnWithText";
import FAQ from "@components/landing/FAQ";
import FeaturesMarquee from "@components/landing/FeaturesMarquee";
import { FadeInView } from "@components/animation/FadeInView";
import { CardsGraphic } from "@components/landing/CardsGraphic";

const Home = ({ plans }) => {
  const { toggleColorMode } = useColorMode();

  const lightGradientBg = useColorModeValue(
    "linear(to-b, gray.100, transparent)",
    "linear(to-b, gray.900, transparent)"
  );
  const color = useColorModeValue("gray.600", "gray.400");

  return (
    <>
      <Navbar />
      <Box>
        <Box bgGradient={lightGradientBg}>
          <Box
            pt={14}
            h="100%"
            backgroundImage={{
              base: "none",
              lg: "url(/img/light-gradient.svg)",
            }}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            position="relative"
          >
            <CTA />

            <Box mt={{ base: 4, md: 0 }}>
              <CardsGraphic />
            </Box>

            <Box mt={{ base: -6, md: 12 }}>
              <FeaturesMarquee />
            </Box>

            <FadeInView>
              <Box
                mt={2}
                as="section"
                maxW="5xl"
                mx="auto"
                py="12"
                px={{
                  base: "6",
                  md: "8",
                }}
              >
                <SimpleGrid
                  columns={{
                    base: 1,
                    md: 2,
                    lg: 3,
                  }}
                  spacingX="10"
                  spacingY={{
                    base: "8",
                    md: "14",
                  }}
                >
                  <Feature title="Unlimited" icon={<Infinity />}>
                    Truly unlimited sessions. Just pay per team seat.
                  </Feature>
                  <Feature title="Planning Poker" icon={<Cards />}>
                    Plan your work using conventional voting cards.
                  </Feature>
                  <Feature title="Retrospective" icon={<Kanban />}>
                    Collect feedback on your team's progress.
                  </Feature>
                  <Feature title="Teams" icon={<UsersFour />}>
                    Create a team & share the Invite Link to get started.
                  </Feature>
                  <Feature
                    title="Full History"
                    icon={<ClockCounterClockwise />}
                  >
                    View past sessions and export them to a .CSV document.
                  </Feature>
                  <Feature title="Dark Mode" icon={<MoonStars />}>
                    Working in the evening? No problem. Dark mode is{" "}
                    <Text
                      cursor="pointer"
                      textDecoration="underline"
                      display="inline-block"
                      onClick={toggleColorMode}
                    >
                      here
                    </Text>
                    .
                  </Feature>
                </SimpleGrid>
              </Box>
            </FadeInView>
          </Box>
        </Box>

        <Box my={24} maxW="5xl" mx="auto">
          <FadeInView>
            <Box mb={16} textAlign="center">
              <HeadingWithSubtitle
                title="Planning Poker & Retrospective Suite"
                subtitle="All-in-one app to estimate story points and collect feedback."
                textProps={{ color }}
                headingProps={{ as: "h2", fontSize: "3xl" }}
              />
            </Box>
          </FadeInView>

          <Stack w="full" spacing={{ base: 12, md: 24 }}>
            <FadeInView>
              <ImageColumnWithText
                src="/img/marketing/teams.jpg"
                title="Teams"
                width="1971px"
                height="1315px"
                desc="Invite your colleagues to a Team with a single link. Sessions are private and only visible to your Team."
              />
            </FadeInView>

            <FadeInView>
              <ImageColumnWithText
                reverse
                src="/img/marketing/planningpoker.jpg"
                title="Planning Poker"
                width="1971px"
                height="1315px"
                desc="Planning Poker is an Agile technique where teams use cards to estimate task complexity and reach consensus."
              />
            </FadeInView>

            <FadeInView>
              <ImageColumnWithText
                src="/img/marketing/retrospective.jpg"
                title="Retrospectives"
                width="1971px"
                height="1315px"
                desc="Retrospectives are Agile meetings where teams reflect on recent work, identify improvements, and plan changes for future sprints."
              />
            </FadeInView>
          </Stack>
        </Box>

        <PricingSection plans={plans} />

        <FadeInView>
          <Box px={{ base: 4, md: 0 }} my={24} maxW="5xl" mx="auto">
            <Box mb={16} textAlign="center">
              <HeadingWithSubtitle
                id="faq"
                title="F.A.Q"
                subtitle="If you have a question, we may have the answer."
                textProps={{ color: "whiteAlpha.600" }}
                headingProps={{ as: "h2", fontSize: "3xl" }}
              />
            </Box>
            <SimpleGrid
              spacing={{ base: 0, md: 6 }}
              columns={{ base: 1, md: 3 }}
            >
              <Box my={4}>
                <Heading as="h3" mb={4} fontSize="xl">
                  Core
                </Heading>
                <FAQ
                  items={[
                    {
                      title: "What is Retro?",
                      desc: "We are an agile planning tool that helps teams plan. We currently offer Retrospective and Planning Poker.",
                    },
                    {
                      title: "What are Teams?",
                      desc: "You invite your colleagues to a Team. Sessions are private and only visible to your Team.",
                    },
                    {
                      title: "What are Lobbies?",
                      desc: "We use the word 'Lobby' to indicate a group of people who are about to start a session.",
                    },
                    {
                      title: "What are Sessions?",
                      desc: "We use the word 'Session' to indicate a group of people who are conducting a Retrospective or playing Planning Poker.",
                    },
                    {
                      title: "How many users can you support?",
                      desc: "In a single lobby or session we are able to support up to 500k concurrent connections.",
                    },
                  ]}
                />
              </Box>
              <Box my={4}>
                <Heading as="h3" fontSize="xl" mb={4}>
                  Payments
                </Heading>
                <FAQ
                  items={[
                    {
                      title: "Can I get invoices for payments?",
                      desc: "Yes! Our payment partner Stripe will generate one for you and email it to you. You can then forward it to your budgeting people!",
                    },
                    {
                      title: "How do payments work?",
                      desc: "Head on over to your Team, press 'Upgrade' and select your desired plan. You will then be billed monthly for your plan until cancelled.",
                    },
                    {
                      title: "How do I cancel my subscription?",
                      desc: "Head on over to the Profile page, click 'Manage Billing' and simply cancel your subscription from the Stripe portal.",
                    },
                    {
                      title: "Do you keep credit card info?",
                      desc: "All payments are conducted outside of our service, therefore no data is stored by us, but our partner Stripe.",
                    },
                  ]}
                />
              </Box>
              <Box my={4}>
                <Heading as="h3" fontSize="xl" mb={4}>
                  Safety
                </Heading>
                <FAQ
                  items={[
                    {
                      title: "Is Retro really secure?",
                      desc: "We don't hold payment data or passwords. We use Stripe to securely process payments. We also use Google Cloud for data storage and authentication.",
                    },
                    {
                      title: "How do I report abuse?",
                      desc: "If you see abusive behaviour on our platform, please reach out to contact@retroplan.io",
                    },
                  ]}
                />
              </Box>
            </SimpleGrid>
          </Box>
        </FadeInView>

        <Footer />
      </Box>
    </>
  );
};

export async function getStaticProps() {
  // Fetch plans from the API
  const getPlans = httpsCallable(functions, "core-getPlans");
  // const getHomepageStats = httpsCallable(functions, "core-getHomepageStats");

  // fetch both with error handling
  let plans;
  // let stats;

  try {
    const res = await getPlans();
    plans = res.data.data;
  } catch (e) {
    console.error("Failed to fetch plans", e);
    plans = [];
  }

  // try {
  //   const res = await getHomepageStats();
  //   stats = res.data.data;
  // } catch (e) {
  //   console.error("Failed to fetch homepage stats", e);
  //   stats = [];
  // }

  return {
    props: {
      plans,
      // stats,
    },
  };
}

export default Home;
