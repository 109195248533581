import {
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ChartBar,
  Clock,
  ClockCounterClockwise,
  CurrencyDollarSimple,
  DeviceMobile,
  Eye,
  Key,
  Lightning,
  MicrosoftExcelLogo,
  Moon,
  Placeholder,
  Rss,
  ThumbsUp,
  Umbrella,
  MoneyWavy,
  PencilSimple,
} from "@phosphor-icons/react";
import Marquee from "react-fast-marquee";

const FeaturesMarquee = () => {
  const gray = useColorModeValue("blackAlpha", "gray");
  const gradientColor = useColorModeValue([255, 255, 255], [23, 25, 35]);

  const features = [
    {
      label: "Encrypted at rest",
      icon: Key,
      colorScheme: "blue",
    },
    {
      label: "Likes & dislikes",
      icon: ThumbsUp,
      colorScheme: "green",
    },
    {
      label: "Custom values",
      icon: PencilSimple,
      colorScheme: "orange",
    },
    {
      label: "Dark mode",
      icon: Moon,
      colorScheme: gray,
    },
    {
      label: "Records Time Taken",
      icon: Clock,
      colorScheme: "red",
    },
    {
      label: "No ads",
      icon: Placeholder,
      colorScheme: "purple",
    },
    {
      label: "<100ms Latency",
      icon: Rss,
      colorScheme: gray,
    },
    {
      label: "Analytics",
      icon: ChartBar,
      colorScheme: "blue",
    },

    {
      label: "One-click Onboarding",
      icon: Lightning,
      colorScheme: "orange",
    },
    {
      label: "Export as .csv",
      icon: MicrosoftExcelLogo,
      colorScheme: "green",
    },
    {
      label: "Made in the UK",
      icon: Umbrella,
      colorScheme: "red",
    },
    {
      label: "Round history",
      icon: ClockCounterClockwise,
      colorScheme: gray,
    },
    {
      label: "Allow spectators",
      icon: Eye,
      colorScheme: "orange",
    },
    {
      label: "Mobile friendly",
      icon: DeviceMobile,
      colorScheme: "green",
    },
    {
      label: "Free Tier",
      icon: CurrencyDollarSimple,
      colorScheme: gray,
    },
    {
      label: "Pay via Stripe",
      icon: MoneyWavy,
      colorScheme: "purple",
    },
  ];
  return (
    <Marquee speed={40} gradientColor={gradientColor}>
      <Stack direction="row" ml={4} spacing={4} py={4}>
        {features.map(({ label, icon, colorScheme }) => (
          <Tag key={label} variant="subtle" size="lg" colorScheme={colorScheme}>
            <TagLeftIcon as={icon} weight="bold" />
            <TagLabel>{label}</TagLabel>
          </Tag>
        ))}
      </Stack>
    </Marquee>
  );
};

export default FeaturesMarquee;
