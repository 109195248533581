import { Box, Flex, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import Image from "next/image";

const ImageColumnWithText = (props) => {
  const { src, title, desc, width, height, reverse } = props;

  return (
    <Flex
      p={{ base: 4, md: 0 }}
      flexDirection={{
        base: "column",
        md: reverse ? "row-reverse" : "row",
      }}
      alignItems="center"
    >
      <Box
        textAlign={{ base: "center", md: reverse ? "right" : "left" }}
        mb={{ base: 10, md: 0 }}
        pl={{ base: 0, md: 4 }}
        pr={{ base: 0, md: reverse ? 4 : 0 }}
        width={{ base: "100%", md: "30%" }}
      >
        <Heading fontSize="2xl" mb={{ base: 0, lg: 2 }}>
          {title}
        </Heading>
        <Text color={useColorModeValue("gray.600", "whiteAlpha.600")}>
          {desc}
        </Text>
      </Box>

      <Box ml={{ base: 0, md: 12 }} width={{ base: "100%", md: "70%" }}>
        <Box
          position="relative"
          shadow={{ base: "lg", md: "xl" }}
          borderRadius="base"
          p={1}
        >
          <Image
            className="rounded-image"
            src={src}
            alt={title}
            width={width}
            height={height}
            layout="responsive"
          />
        </Box>
      </Box>
    </Flex>
  );
};

export default ImageColumnWithText;
